import { createApp } from 'vue'
import App from './App.vue'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'

import 'photo-sphere-viewer/dist/plugins/markers.css'

import 'photo-sphere-viewer/dist/plugins/gallery.css'

import 'photo-sphere-viewer/dist/plugins/virtual-tour.css'




createApp(App).mount('#app')
