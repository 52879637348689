<template>
  <div class="hello">
    <div id="viewer" style="width: 100vw; height: 100vh;"></div>
  </div>
</template>

<script>
import { Viewer } from 'photo-sphere-viewer'

// 这里只用到了标签插件/图库插件/虚拟漫游插件这三个插件，如果用陀螺仪插件的话域名必须是https的。

import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers';

import { GalleryPlugin } from 'photo-sphere-viewer/dist/plugins/gallery';

import { VirtualTourPlugin } from 'photo-sphere-viewer/dist/plugins/virtual-tour';

let PSV = null

let markersPlugin = null

let virtualTourPlugin = null
export default {
  name: 'HelloWorld',
  data() {
    return {
      baseUrl: 'https://img.ziye888.com/dadiebao/vr/'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const _this = this
      console.log(document.querySelector("#viewer"))
      PSV = new Viewer({
        container: document.querySelector("#viewer"),
        navbar: [ // 底部导航栏，依次是自动播放、缩放、图库、文字、全屏
          // 'autorotate',
          // 'zoom',
          'gallery',
          // 'caption',
          // 'fullscreen',
        ],
        lang: { // 鼠标移到导航栏显示的文字说明
          autorotate: '自动旋转',
          zoom      : '缩放',
          zoomOut   : '缩小',
          zoomIn    : '放大',
          gallery   : '场景选择',
          fullscreen: '全屏'
        },
        defaultZoomLvl: 0,
        autorotateDelay: 1000,
        autorotateIdle: true,
        autorotateSpeed: '1rpm',
        plugins: [ // 用到的插件需要在这里提前写入
          [MarkersPlugin, {
            // markers: arr
          }],
          [GalleryPlugin, {
            visibleOnLoad: false,
            thumbnailSize: {
              width: 80,
              height:80
            }
          }],
          [VirtualTourPlugin, {
            // positionMode: VirtualTourPlugin.MODE_GPS,
            renderMode  : VirtualTourPlugin.MODE_MANUAL,
          }]
        ]
      })
// 标记
      markersPlugin = PSV.getPlugin(MarkersPlugin); // 所有插件用到的时候都需要提前获取
// 标记的事件用on直接写，具体有哪些可以查官网https://photo-sphere-viewer-4.netlify.app/
      markersPlugin.on('select-marker', function(e) {
        console.log('psv-marker:', e)
      });
// 漫游
      virtualTourPlugin = PSV.getPlugin(VirtualTourPlugin);
      let arrowStyle = { // 自定义箭头颜色，但没办法改箭头的图案，就很不人性化
        color       : 0xffffff,
        hoverColor  : 0xaa5500,
        outlineColor: 0x7fdcdcdc,
        // scale       : [1, 4],
      }
      virtualTourPlugin.setNodes([
        {
          id: '1',
          panorama : _this.baseUrl + '前台.jpg',
          thumbnail: _this.baseUrl + '前台-thumb.jpg', // 缩略图
          name: '前台', // 图库和箭头显示的文字
          links: [
            { nodeId: '5', arrowStyle,latitude:-0.3,longitude:-0.2 }, // 星际
            { nodeId: '14', arrowStyle,  latitude:-0.2,longitude:1.06  }, // 过道4
            { nodeId: '11', arrowStyle, latitude:-0.1,longitude:2    }, // 过道1
            { nodeId: '6', arrowStyle, latitude:-0.2,longitude:-2.3   }, // 8301
            { nodeId: '4', arrowStyle,latitude:-0.2,longitude:-2.2    }, // 星河
          ]
        },
        {
          id: '2',
          panorama: _this.baseUrl + '无限.jpg',// 全景图
          thumbnail: _this.baseUrl + '无限-thumb.jpg', // 图库插件的缩略图
          name: '无限',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '13', arrowStyle, latitude:-0.25,longitude:-0.52  }, // 过道3
          ],
        },
        {
          id: '3',
          panorama: _this.baseUrl + '无极.jpg',// 全景图
          thumbnail: _this.baseUrl + '无极-thumb.jpg', // 图库插件的缩略图
          name: '无极',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '12', arrowStyle,latitude:-0.25,longitude:-0.6   }, // 过道2
          ],
        },
        {
          id: '4',
          panorama: _this.baseUrl + '星河.jpg',// 全景图
          thumbnail: _this.baseUrl + '星河-thumb.jpg', // 图库插件的缩略图
          name: '星河',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '1', arrowStyle,latitude:-0.3,longitude:-2.7   }, // 前台 // 有来有回，节点1中展示2，那节点2中必须有1，这里的nodeId应对节点的id
          ],
        },
        {
          id: '5',
          panorama: _this.baseUrl + '星际.jpg',// 全景图
          thumbnail: _this.baseUrl + '星际-thumb.jpg', // 图库插件的缩略图
          name: '星际',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '1', arrowStyle,latitude:-0.2,longitude:0.6   },// 前台
          ]
        },
        {
          id: '6',
          panorama: _this.baseUrl + '8301.jpg',// 全景图
          thumbnail: _this.baseUrl + '8301-thumb.jpg', // 图库插件的缩略图
          name: '8301',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '1', arrowStyle,latitude:-0.2,longitude:-0.57   }, // 前台
          ],
        },
        {
          id: '7',
          panorama: _this.baseUrl + '8306.jpg',// 全景图
          thumbnail: _this.baseUrl + '8306-thumb.jpg', // 图库插件的缩略图
          name: '8306',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '12', arrowStyle,latitude:-0.3,longitude:-0.83   }, // 过道2
          ],
        },
        {
          id: '8',
          panorama: _this.baseUrl + '8309.jpg',// 全景图
          thumbnail: _this.baseUrl + '8309-thumb.jpg', // 图库插件的缩略图
          name: '8309',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '14', arrowStyle,latitude:-0.3,longitude:2.2   }, // 过道4
          ],
        },
        {
          id: '9',
          panorama: _this.baseUrl + '8310.jpg',// 全景图
          thumbnail: _this.baseUrl + '8310-thumb.jpg', // 图库插件的缩略图
          name: '8310',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '14', arrowStyle,latitude:-0.2,longitude:-0.6   }, // 过道4
          ],
        },
        {
          id: '10',
          panorama: _this.baseUrl + '8311.jpg',// 全景图
          thumbnail: _this.baseUrl + '8311-thumb.jpg', // 图库插件的缩略图
          name: '8311',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '13', arrowStyle,latitude:-0.2,longitude:-2.3   }, // 过道3
          ],
        },
        {
          id: '11',
          panorama: _this.baseUrl + '过道1.jpg',// 全景图
          thumbnail: _this.baseUrl + '过道1-thumb.jpg', // 图库插件的缩略图
          name: '过道1',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '1', arrowStyle,latitude:-0.2,longitude:-1.5  }, // 前台
            { nodeId: '12', arrowStyle,latitude:-0.2,longitude:1.65   }, // 过道2
          ],
        },
        {
          id: '12',
          panorama: _this.baseUrl + '过道2.jpg',// 全景图
          thumbnail: _this.baseUrl + '过道2-thumb.jpg', // 图库插件的缩略图
          name: '过道2',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '11', arrowStyle,latitude:-0.1,longitude:1.55  }, // 过道1
            { nodeId: '14', arrowStyle,latitude:-0.3,longitude:3.1  }, // 过道4
            { nodeId: '7', arrowStyle,latitude:-0.37,longitude:4.2   }, // 8306
            { nodeId: '3', arrowStyle,latitude:-0.4,longitude:0   }, // 无极
          ],
        },
        {
          id: '13',
          panorama: _this.baseUrl + '过道3.jpg',// 全景图
          thumbnail: _this.baseUrl + '过道3-thumb.jpg', // 图库插件的缩略图
          name: '过道3',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '10', arrowStyle,latitude:-0.156,longitude:-1.8  }, // 8311
            { nodeId: '14', arrowStyle,latitude:-0.1,longitude:-1.64   },// 过道4
            { nodeId: '2', arrowStyle,latitude:-0.44,longitude:-0.43   },// 无限
            { nodeId: '1', arrowStyle,latitude:-0.1,longitude:-0.05   },// 前台
          ],
        },
        {
          id: '14',
          panorama: _this.baseUrl + '过道4.jpg',// 全景图
          thumbnail: _this.baseUrl + '过道4-thumb.jpg', // 图库插件的缩略图
          name: '过道4',
          links: [ // 点击箭头后需要展示的全景图，
            { nodeId: '12', arrowStyle,latitude:-0.2,longitude:3.1   }, // 过道2
            { nodeId: '13', arrowStyle,latitude:-0.1,longitude:-1.55   }, // 过道3
            { nodeId: '10', arrowStyle,latitude:-0.08,longitude:-1.39   }, // 8311
            { nodeId: '9', arrowStyle,latitude:-0.15,longitude:1.05   }, // 8310
            { nodeId: '8', arrowStyle,latitude:-0.18,longitude:1.4   }, // 8309
          ],
        }

      ])

      PSV.once('ready', () => {
        markersPlugin.showAllTooltips()
        // markersPlugin.showMarkerTooltip('custom-tooltip');
      });
      // 切换后的回调
      virtualTourPlugin.on('node-changed', (e, nodeId, data) => {
        const { fromLink, fromLinkPosition, fromNode } = data
        console.log('当前节点信息：', fromLink)
        console.log('上一节点经纬度（弧度）：',fromLinkPosition)
        console.log('上一节点信息：', fromNode)
        markersPlugin.showAllTooltips()
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-tooltip {
  max-width: none;
  /*width: 300px;*/
  padding: 8px 16px;
}
</style>
